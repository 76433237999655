// Libraries 
import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter, CompatRoute } from "react-router-dom-v5-compat"
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./store";
import { Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Font
import '@fontsource/roboto'

// Module: Common services
import App from "./containers/app";
// import Nav from "./components/navbar/Navbar"
import NavbarNew from "./components/navbar/NavbarNew"

import Home from './containers/common_services/home';
import SurveyManual from './containers/common_services/survey_manual';

import { LinearProgress } from '@mui/material';

// Global Styles
import theme from "./theme";
import './index.css'

// Module: KM
import ParametersProvider from "./containers/knowledge_management/searchParameters";
const PrincipalView = lazy(() => import('./containers/knowledge_management/principalView'))
const DocEdit = lazy(() => import('./containers/knowledge_management/editDoc'))
const DocList = lazy(() => import('./containers/knowledge_management/docList'))
const DocView = lazy(() => import('./containers/knowledge_management/docView'))
const CreateDoc = lazy(() => import('./containers/knowledge_management/createDoc'))
const Taxonomy = lazy(() => import('./containers/knowledge_management/taxonomy'))
const ClientList = lazy(() => import('./containers/knowledge_management/clientList'))
const CreateClient = lazy(() => import('./containers/knowledge_management/createClient'))
const ClientView = lazy(() => import('./containers/knowledge_management/clientView'))
const EditClient = lazy(() => import('./containers/knowledge_management/editClient'))
const ProjectList = lazy(() => import('./containers/knowledge_management/projectList'))
const ProjectView = lazy(() => import('./containers/knowledge_management/projectView'))
const ProjectEdit = lazy(() => import('./containers/knowledge_management/editProject'))
const ProjectClosingForm = lazy(() => import('./containers/knowledge_management/projectClosingForm'))
const CreateProject = lazy(() => import('./containers/knowledge_management/createProject'))
const Search = lazy(() => import('./containers/knowledge_management/search'))

const ProtectedRoute = ({ component: Component, requiredRoles, ...rest }) => {
  const userPosition = store.getState().user.consultant.position;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (requiredRoles.includes(userPosition)) {
          return <Component {...props} />;
        } else {
          // redirigir a página de inicio
          return <Redirect to="/home" />;
        }
      }}
    />
  );
};

// Module staffing
const Vacations = lazy(() => import('./containers/staffing/vacationList'))
const AssignationsByWeek = lazy(() => import('./containers/staffing/assignations'))
const Availability = lazy(() => import('./containers/staffing/availability'))
const Consultants = lazy(() => import('./containers/staffing/consultants'))
const ConsultantExperience = lazy(() => import('./containers/staffing/consultantExperience'))


// Module: Performance
const PerformanceSearch = lazy(() => import('./containers/performance_eval/search'))
const EvalAdmin = lazy(() => import('./containers/performance_eval/eval_panel'))
const Competencies = lazy(() => import('./containers/performance_eval/competencies'))
const NewCompetency = lazy(() => import('./containers/performance_eval/new_competency'))
const Competency = lazy(() => import('./containers/performance_eval/competency'))
const Quarters = lazy(() => import('./containers/performance_eval/processes'))
const CalibrationLayout = lazy(() => import('./containers/performance_eval/calibrationLayout'))
const Engagement = lazy(() => import('./containers/performance_eval/engagement'))
const SingleProcess = lazy(() => import('./containers/performance_eval/single_process'))
const Quarter = lazy(() => import('./containers/performance_eval/process'))
const ActionPlans = lazy(() => import('./containers/performance_eval/actionPlans'))
const Standarization = lazy(() => import('./containers/performance_eval/standarization'))
const QuarterFollow = lazy(() => import('./containers/performance_eval/quarterFollow'))
const MenteeOverview = lazy(() => import('./containers/performance_eval/menteeOverview'))
const Main = lazy(() => import('./containers/performance_eval/main'))
const Evaluation = lazy(() => import('./containers/performance_eval/evaluation'))
const Formal = lazy(() => import('./containers/performance_eval/formalev'))
const Requests = lazy(() => import('./containers/performance_eval/requests'))

const target = document.querySelector("#root");
const queryClient = new QueryClient()

// Module: Common Services
const Profile = lazy(() => import('./containers/common_services/profile'))

render(

  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <ParametersProvider>
              <CompatRouter>
                <QueryClientProvider client={queryClient}>
                  <App>
                    <div style={{ flexGrow: 1 }}>
                      <NavbarNew>
                        <Suspense fallback={<LinearProgress />}>
                          <Switch>
                            {/* Common Services */}
                            <Route exact path="/profile/:id">
                              <Profile />
                            </Route>
                            <Route exact path="/performance/requests/" component={Requests} />
                            <Route exact path="/home/">
                              <Home />
                            </Route>
                            <Route exact path="/performance/" component={Main} />
                            <Route exact path="/performance/search/" component={PerformanceSearch} />
                            <ProtectedRoute
                              exact
                              path="/performance/admin/"
                              component={EvalAdmin}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                            />
                            <ProtectedRoute
                              exact
                              path="/performance/competencies/"
                              component={Competencies}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                            />
                            <ProtectedRoute
                              exact
                              path="/performance/competencies/new"
                              component={NewCompetency}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                            />
                            <ProtectedRoute
                              exact
                              path="/performance/competencies/:cid/"
                              component={Competency}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                            />

                            <Route exact path="/performance/quarter/" component={Quarters} />
                            <Route exact path="/performance/quarter/:id/" component={Quarter} />
                            <Route exact path="/performance/quarter/:id/followup/" component={QuarterFollow} />
                            <Route exact path="/performance/actionPlans/" component={ActionPlans} />
                            <Route exact path="/performance/quarter/:id/menteeOverview/" component={MenteeOverview} />
                            <Route exact path="/performance/quarter/:id/process/:pid/" component={SingleProcess} />
                            <Route exact path="/performance/quarter/:qid/process/:pid/evaluations/:eid/" component={Evaluation} />
                            <Route exact path="/performance/quarter/:qid/process/:pid/formal/:eid/" component={Formal} />
                            <Route exact path="/performance/quarter/:qid/process/:id/calibrate" component={CalibrationLayout} />
                            <Route exact path="/performance/quarter/:qid/process/:id/standarize" component={Standarization} />
                            <Route exact path="/performance/:qid/" component={Main} />
                            <CompatRoute exact path="/performance/quarter/:qid/engagement">
                              <Engagement />
                            </CompatRoute>
                            {/* modulo_km */}
                            {/* <Route exact path="/modulo_km/" component={principalView} /> */}
                            <Route path="/modulo_km/" component={PrincipalView}>
                              <Route exact path="/modulo_km/" component={PrincipalView} />
                              <Route exact path="/modulo_km/knowledge_map/">
                                <Home />
                              </Route>
                              <Route exact path="/modulo_km/survey_manual/">
                                <SurveyManual />
                              </Route>
                              <Route exact path="/modulo_km/search/" component={Search} />
                              <Route exact path="/modulo_km/doc_list/" component={DocList} />
                              <Route exact path="/modulo_km/doc_list/:id/" component={DocView} />
                              <Route exact path="/modulo_km/edit_doc/:id/" component={DocEdit} />
                              <Route exact path="/modulo_km/create_doc/" component={CreateDoc} />
                              <Route exact path="/modulo_km/taxonomy/" component={Taxonomy} />
                              <Route exact path="/modulo_km/client_list/" component={ClientList} />
                              <Route exact path="/modulo_km/create_client/" component={CreateClient} />
                              <Route exact path="/modulo_km/client_view/:id/" component={ClientView} />
                              <Route exact path="/modulo_km/edit_client/:id/" component={EditClient} />
                              <Route exact path="/modulo_km/project_list/" component={ProjectList} />
                              <Route exact path="/modulo_km/project_view/:id/" component={ProjectView} />
                              <Route exact path="/modulo_km/edit_project/:id/" component={ProjectEdit} />
                              <Route exact path="/modulo_km/create_project/" component={CreateProject} />
                              <Route exact path="/modulo_km/project_closing_form/:id/" component={ProjectClosingForm} />
                              <Route exact path="/modulo_km/consultants/" component={Consultants} />
                            </Route>
                            {/* Staffing */}
                            
                            <ProtectedRoute
                              exact
                              path="/staffing/vacations/"
                              component={Vacations}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                              />
                            <ProtectedRoute
                              exact
                              path="/staffing/assignations/"
                              component={AssignationsByWeek}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                              />
                            <ProtectedRoute
                              exact
                              path="/staffing/availability/"
                              component={Availability}
                              requiredRoles={['Socio', 'Director', 'Administrativo']}
                              />
                            <Route exact path="/staffing/vacations/" component={Vacations} />
                            <Route exact path="/staffing/assignations/" component={AssignationsByWeek} />
                            <Route exact path="/staffing/availability/" component={Availability} />
                            <Route exact path="/staffing/consultants/table" component={ConsultantExperience} /> 
                          </Switch>
                        </Suspense>
                      </NavbarNew>
                    </div>
                  </App>
                </QueryClientProvider>
              </CompatRouter>
            </ParametersProvider>
          </BrowserRouter>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider >,
  target
);
