// Los elementos de la barra lateral se definen en la variable sideBar.
// El motivo de que no esten definidos en otro archivo es que se necesita que el tipo de usuario
// este definido para poder mostrar o no ciertos elementos de la barra lateral y este no se
// carga bien si se define en otro archivo (las cualidades KM o ser socio por ejemplo).

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import theme from "../../theme";
import logo_summa_blanco from "../../assets/summa_white.png";

import Timeline from "@mui/icons-material/Timeline";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import SearchIcon from "@mui/icons-material/Search";
import StorageIcon from "@mui/icons-material/Storage";
import PersonIcon from "@mui/icons-material/Person";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import GroupsIcon from "@mui/icons-material/Groups";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import BarChartIcon from "@mui/icons-material/BarChart";
import SortIcon from "@mui/icons-material/Sort";
import ConstructionIcon from '@mui/icons-material/Construction';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Notifications from "../../containers/common_services/notifications";

import {
  Box,
  Drawer,
  CssBaseline,
  List,
  Toolbar,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Container,
  Menu,
  MenuItem,
  Collapse,
  Divider,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { AUTH_TOKEN } from "../../constants/authConstants";
import { Link } from "react-router-dom";
import store from "../../store";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const drawerWidth = 260;

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
    }),
    marginLeft: 0,
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function Navbar(props) {
  // const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openSideBar, setOpenSideBar] = useState(
    // [...Array(sideBar.length)].map((x) => true)
    [true, true, true, true]
  );
  const consultant_isKM = store.getState().user.consultant.isKM;
  const consultant_isAdmin = ["Administrativo", "Socio", "Director"].includes(
    store.getState().user.consultant.position
  );

  const handleClick = (id) => {
    let newOpenSideBar = [...openSideBar];
    newOpenSideBar[id] = !newOpenSideBar[id];
    setOpenSideBar(newOpenSideBar);
  };

  const handleOpenUserMenu = (event) => {
    event.preventDefault();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    window.location.reload();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let consultant_id = store.getState().user.consultant_id;
  let consultant_name = store.getState().user.consultant.name;

  const sideBar = [
    {
      name: "Desempeño",
      id: 0,
      key: "desempeño",
      icon: <Timeline sx={{ fontSize: 25 }} />,
      subAreas: [
        {
          name: "Home",
          link: "/performance/",
          key: "home_desempeño",
          icon: <HomeIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Buscador",
          link: "/performance/search/",
          key: "search_desempeño",
          icon: <SearchIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Solicitudes",
          link: "/performance/requests",
          key: "requests_desempeño",
          icon: <VpnKeyIcon sx={{ fontSize: 23 }} />,
        },
        ...(consultant_isAdmin
          ? [
              {
                name: "Panel administrativo",
                link: "/performance/admin/",
                key: "admin_panel",
                icon: <BuildIcon sx={{ fontSize: 23 }} />,
              },
              {
                name: "Calibraciones",
                link: "/performance/quarter/49/",
                key: "calibrations",
                icon: <BarChartIcon sx={{ fontSize: 23 }} />,
              },
            ]
          : []),
      ],
    },
   
    {
      name: "Módulo KM",
      id: 1,
      key: "module_km",
      icon: <CreateNewFolderIcon sx={{ fontSize: 25 }} />,
      subAreas: [
        {
          name: "Home",
          link: "/modulo_km/",
          key: "home_km",
          icon: <HomeIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Mapa del conocimiento",
          link: "/modulo_km/knowledge_map/",
          key: "knowledge_map",
          icon: <MapIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Manual de Encuestas",
          link: "/modulo_km/survey_manual/",
          key: "survey_manual",
          icon: <ClearAllIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Búsqueda",
          link: "/modulo_km/search/",
          key: "search",
          icon: <SearchIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Documentos",
          link: "/modulo_km/doc_list/",
          key: "doc_list",
          icon: <StorageIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Clientes",
          link: "/modulo_km/client_list/",
          key: "client_list",
          icon: <PersonIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Proyectos",
          link: "/modulo_km/project_list/",
          key: "pproject_list",
          icon: <FolderCopyIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Experiencia en Summa",
          link: "/modulo_KM/consultants",
          key: "consultants",
          icon: <SortIcon sx={{ fontSize: 23 }} />,
        },
        // El elemento de "Gestión de Taxonomía" solo se incluirá si consultant_isKM es true
        ...(consultant_isKM
          ? [
              {
                name: "Gestión de Taxonomía",
                link: "/modulo_km/taxonomy/",
                key: "taxonomy",
                icon: <AccountTreeIcon sx={{ fontSize: 23 }} />,
              },
            ]
          : []),
      ],
    },
    {
      name: "Herramientas",
      id: 2,
      key: "herramientas",
      icon: <ConstructionIcon sx={{ fontSize: 25 }} />,
      subAreas: [
        {
          name: "Consolidación de Encuestas",
          link: "https://encuestas-summa-production.up.railway.app/",
          key: "consolidacion_encuestas",
          icon: <CloudUploadIcon sx={{ fontSize: 23 }} />,
        },
        {
          name: "Cost Driver",
          link: "https://cost-driver-tool-production-2831.up.railway.app/",
          key: "cost_driver",
          icon: <BarChartIcon sx={{ fontSize: 23 }} />,
        }
      ],
    },
    ...(consultant_isAdmin
      ? [
          {
            name: "Staffing",
            id: 3,
            key: "staffing",
            icon: <GroupsIcon sx={{ fontSize: 25 }} />,
            subAreas: [
              {
                name: "Vacaciones",
                link: "/staffing/vacations",
                key: "vacations",
                icon: <BeachAccessIcon sx={{ fontSize: 23 }} />,
              },
              {
                name: "Asignaciones",
                link: "/staffing/assignations",
                key: "assignations",
                icon: <AssignmentIndIcon sx={{ fontSize: 23 }} />,
              },
              {
                name: "Disponibilidad",
                link: "/staffing/availability",
                key: "availability",
                icon: <BorderAllIcon sx={{ fontSize: 23 }} />,
              },
            ],
          },
        ]
      : []),
  ];

  const bottomOptions = [
    {
      name: "Perfil",
      link: "/profile/" + consultant_id + "/",
      key: "profile",
      icon: <AccountCircleIcon sx={{ fontSize: 23 }} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Container maxWidth="xxl" style={{ backgroundColor: "#014169" }}>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex" },
              }}
            >
              {open === false && (
                <IconButton
                  sx={{ color: "white", fontSize: 40, mr: 1, ml: -1 }}
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {open === false && (
                <Link to={"/home/"}>
                  <Box
                    component="img"
                    sx={{
                      height: "40px",
                      width: "151px",
                      mt: 1,
                      mb: 1,
                      display: { xs: "flex", md: "flex" },
                    }}
                    src={logo_summa_blanco}
                  />
                </Link>
              )}
            </Box>
            <Box sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 9999,
                }}
              >
                <Notifications />
                <AccountCircleIcon
                  fontSize="medium"
                  style={{ verticalAlign: "center" }}
                />

                <Typography ml={1}>
                  <Link
                    to="#"
                    onClick={handleOpenUserMenu}
                    style={{ color: "white", fontSize: 18 }}
                  >
                    {consultant_name}
                  </Link>
                </Typography>
              </Box>
              <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AccountCircleIcon
                      fontSize="medium"
                      style={{ verticalAlign: "center", marginRight: "5px" }}
                    />
                    <Typography textAlign="center">
                      <Link
                        to={"/profile/" + consultant_id + "/"}
                        style={{ color: "black" }}
                      >
                        Perfil
                      </Link>
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ExitToAppIcon
                      fontSize="medium"
                      style={{ verticalAlign: "center", marginRight: "5px" }}
                    />
                    <Typography textAlign="center">
                      <Link to={"/"} style={{ color: "black" }}>
                        Cerrar sesión
                      </Link>
                    </Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#FAFAFA",
            borderRight: "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "#014169",
            zIndex: 1201,
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "rgba(0,0,0,0)",
              borderRight: "none",
            },
          }}
        >
          {theme.direction === "ltr" && (
            <IconButton
              sx={{ color: "white", fontSize: 40, mr: 1 }}
              onClick={handleDrawerClose}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to={"/home/"}>
            <Box
              component="img"
              sx={{
                height: "40px",
                width: "151px",
                mt: 1,
                mb: 1,
                display: { xs: "flex", md: "flex" },
              }}
              src={logo_summa_blanco}
            />
          </Link>
        </DrawerHeader>

        <Box
          sx={{
            overflowY: "auto",
            scrollbarWidth: "none" /* For Firefox */,
            "&::-webkit-scrollbar": {
              /* For Chrome, Safari, and Opera */ display: "none",
            },
          }}
        >
          {sideBar.map((area) => (
            <List
              sx={{ backgroundColor: "#faFaFa", width: "100%", maxWidth: 320 }}
              key={area.key}
              dense={true}
            >
              <ListItem key={area.key} disablePadding>
                <ListItemButton onClick={() => handleClick(area.id)}>
                  <ListItemIcon style={{ color: "black" }}>
                    {area.icon}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography={true}
                    sx={{ fontSize: 16, color: "black" }}
                  >
                    {area.name}
                  </ListItemText>
                  {openSideBar[area.id] ? (
                    <ExpandLess sx={{ color: "black" }} />
                  ) : (
                    <ExpandMore sx={{ color: "black" }} />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={openSideBar[area.id]} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  dense={true}
                  disablePadding
                  sx={{ backgroundColor: "#FAFAFA" }}
                >
                  {area.subAreas.map((subArea) => {
                    const isExternal = subArea.link.startsWith("http://") || subArea.link.startsWith("https://");

                    return isExternal ? (
                      <a
                        href={subArea.link}
                        key={subArea.key}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{color: "black" }}
                      >
                        <ListItem key={subArea.key} disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            style={
                              subArea.key === selectedLink
                                ? { backgroundColor: "rgba(0,0,0,0.05)" }
                                : {}
                            }
                          >
                            <ListItemIcon
                              style={
                                subArea.key === selectedLink
                                  ? { color: "rgb(1 65 105)" }
                                  : { color: "black" }
                              }
                            >
                              {subArea.icon}
                            </ListItemIcon>
                            <ListItemText
                              disableTypography={true}
                              sx={{ fontSize: 14 }}
                              style={
                                subArea.key === selectedLink
                                  ? { color: "rgb(1 65 105)" }
                                  : {}
                              }
                            >
                              {subArea.name}
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </a>
                    ) : (
                      <Link
                        to={subArea.link}
                        key={subArea.key}
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={() => setSelectedLink(subArea.key)}
                      >
                        <ListItem key={subArea.key} disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            style={
                              subArea.key === selectedLink
                                ? { backgroundColor: "rgba(0,0,0,0.05)" }
                                : {}
                            }
                          >
                            <ListItemIcon
                              style={
                                subArea.key === selectedLink
                                  ? { color: "rgb(1 65 105)" }
                                  : { color: "black" }
                              }
                            >
                              {subArea.icon}
                            </ListItemIcon>
                            <ListItemText
                              disableTypography={true}
                              sx={{ fontSize: 14 }}
                              style={
                                subArea.key === selectedLink
                                  ? { color: "rgb(1 65 105)" }
                                  : {}
                              }
                            >
                              {subArea.name}
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    );
                  })}
                </List>
              </Collapse>
            </List>
          ))}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Divider variant="middle" />
        {bottomOptions.map((option) => (
          <Link
            key={option}
            to={option.link}
            style={{ color: "black" }}
            onClick={() => setSelectedLink(option.key)}
          >
            <ListItem key={option.key} disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                style={
                  option.key === selectedLink
                    ? { backgroundColor: "rgba(0,0,0,0.05)" }
                    : {}
                }
              >
                <ListItemIcon
                  style={
                    option.key === selectedLink
                      ? { color: "rgb(1 65 105)" }
                      : { color: "black" }
                  }
                >
                  {option.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  sx={{ fontSize: 14 }}
                  style={
                    option.key === selectedLink
                      ? { color: "rgb(1 65 105)" }
                      : {}
                  }
                >
                  {option.name}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </Drawer>
      <Main open={open} style={{ display: "flex", flexDirection: "column" }}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  );
}
